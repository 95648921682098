import React, { useState } from "react";
import { Link } from "gatsby";
import Logo from "./Logo";

const Navigation = ({ navigationBackground }) => {
  const [navOpen, setNavOpen] = useState(false);

  return (
    <nav
      className={`navigation${navigationBackground ? " background" : ""} ${
        navOpen ? "open" : ""
      }`}
    >
      <div className="navigation-container">
        <Logo navOpen={navOpen} />
        <div className="navigation-desktop">
          <ul className="navigation-list">
            <li className="navigation-item">
              <Link to="/" activeClassName="active">
                Etusivu
              </Link>
            </li>
            <li className="navigation-item">
              <Link to="/palvelut" activeClassName="active">
                Palvelut
              </Link>
            </li>
            <li className="navigation-item">
              <Link to="/ajanvaraus" activeClassName="active">
                Ajanvaraus
              </Link>
            </li>
            <li className="navigation-item">
              <Link to="/lahjakortti" activeClassName="active">
                Lahjakortti
              </Link>
            </li>
            <li className="navigation-item">
              <Link to="/yhteystiedot" activeClassName="active">
                Yhteystiedot
              </Link>
            </li>
            <li className="navigation-item">
              <Link to="/galleria" activeClassName="active">
                Galleria
              </Link>
            </li>
            <li className="navigation-item">
              <a
                href="https://www.exuviance.fi/#clinic=kauneudenilo&amp;link=73580d71"
                style={{ fontSize: ".8rem" }}
                target="_blank"
                rel="noreferrer"
              >
                Exuviance verkkokauppa
              </a>
            </li>
          </ul>
        </div>
        <div className="navigation-mobile">
          <button
            className={`hamburger-menu ${navOpen ? "open" : ""}`}
            onClick={() => setNavOpen(!navOpen)}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>
          {navOpen ? (
            <div className={`overlay ${navOpen ? "open" : ""}`}>
              <ul className="navigation-list">
                <li className="navigation-item">
                  <Link to="/" activeClassName="active">
                    Etusivu
                  </Link>
                </li>
                <li className="navigation-item">
                  <Link to="/palvelut" activeClassName="active">
                    Palvelut
                  </Link>
                </li>
                <li className="navigation-item">
                  <Link to="/ajanvaraus" activeClassName="active">
                    Ajanvaraus
                  </Link>
                </li>
                <li className="navigation-item">
                  <Link to="/lahjakortti" activeClassName="active">
                    Lahjakortti
                  </Link>
                </li>
                <li className="navigation-item">
                  <Link to="/yhteystiedot" activeClassName="active">
                    Yhteystiedot
                  </Link>
                </li>
                <li className="navigation-item">
                  <Link to="/galleria" activeClassName="active">
                    Galleria
                  </Link>
                </li>
                <li className="navigation-item">
                  <a
                    href="https://www.exuviance.fi/#clinic=kauneudenilo&amp;link=73580d71"
                    className="button--outline light normal"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Exuviance verkkokauppa
                  </a>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
